import { useEffect, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { useWindowEventListener } from "./listeners";

export function useIsScrolling() {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timeoutId;

    function handleScroll() {
      clearTimeout(timeoutId);
      setIsScrolling(true);

      timeoutId = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isScrolling;
}

export function useOutsideAlerter(ref, handleClick = () => null, props = []) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...props]);
}

export function useCountdown(duration, onEnd, end = 0) {
  const [countDown, setCountDown] = useState(duration);
  let interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      setCountDown((current) => current - 1);
    }, 1000);

    return () => clearInterval(interval.current);
  }, [onEnd]);

  useEffect(() => {
    if (countDown !== end) return;
    if (typeof onEnd === "function") onEnd();
    else setCountDown(duration);
  }, [duration, countDown, onEnd, end]);

  return {
    countDown,
  };
}

export const useIOSSafeHeight = () => {
  useIsomorphicLayoutEffect(() => {
    if (typeof window === "undefined") return;
    document.documentElement.style.setProperty(
      "--vh",
      window.innerHeight * 0.01 + "px"
    );
  }, []);

  useWindowEventListener(
    "resize",
    () => {
      document.documentElement.style.setProperty(
        "--vh",
        window.innerHeight * 0.01 + "px"
      );
    },
    []
  );

  return `calc(var(--vh, 1vh) * 100)`;
};
