import { RefreshIcon } from "@heroicons/react/outline";
import {
  BookmarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  LockClosedIcon,
  Share2Icon,
} from "@radix-ui/react-icons";
import { format } from "date-fns";
import { RocketIcon, WifiIcon } from "lucide-react";
import { cn } from "ui/lib/utils";

export const iPhoneModels = {
  IPHONE_15_PRO: "iPhone 15 Pro",
  IPHONE_15_PRO_MAX: "iPhone 15 Pro Max",
  IPHONE_14_PRO: "iPhone 14 Pro",
  IPHONE_14_PRO_MAX: "iPhone 14 Pro Max",
};

export const iPhoneDimensions = {
  [iPhoneModels.IPHONE_15_PRO]: { width: 1179, height: 2556, hasPill: true },
  [iPhoneModels.IPHONE_15_PRO_MAX]: {
    width: 1290,
    height: 2796,
    hasPill: true,
  },
  [iPhoneModels.IPHONE_14_PRO]: { width: 1170, height: 2532, hasNotch: true },
  [iPhoneModels.IPHONE_14_PRO_MAX]: {
    width: 1284,
    height: 2778,
    hasNotch: true,
  },
};

export const PhoneOutline = ({
  children,
  id = "phone-outline",
  showBrowser = true,
  model = iPhoneModels.IPHONE_15_PRO,
  paddingTop = 45,
}) => {
  const { width, height, hasPill, hasNotch } =
    iPhoneDimensions[model] || iPhoneDimensions["iPhone 15"];

  return (
    <div
      id={id}
      style={{
        width: width / 3,
        height: height / 3,
        borderRadius: 50,
        boxShadow:
          "rgb(255 255 255 / 87%) 0px 0px 2px 0px, rgb(0 0 0 / 99%) 0px 0px 10px 0px",
      }}
      className="relative m-0 overflow-hidden scale-90 bg-card border-4 shadow-xl iphone border-neutral-900"
    >
      <div
        style={{
          paddingTop,
          paddingBottom: 135,
        }}
        className="overflow-x-hidden overflow-y-scroll w-full h-full"
      >
        <div
          className={cn(
            "absolute top-0 pb-1 bg-card w-full z-10 flex items-center justify-around px-8 text-foreground",
            {
              "pt-2": hasPill,
            }
          )}
        >
          <span style={{ width: 40 }} className="text-sm text-center">
            {format(new Date(), "h:mm")}
          </span>
          {hasPill && (
            <div
              style={{
                width: 160,
                height: 35,
                borderRadius: 20,
              }}
              className="z-10 bg-black flex items-center justify-center"
            >
              <div
                style={{
                  boxShadow:
                    "0 1px 2px rgb(255 255 255 / 12%), inset 0 -0.5px 0 rgb(255 255 255 / 15%), inset 0 0 1px rgb(0 0 0), inset 0 2px 2px rgb(0 0 0 / 45%)",
                }}
                className="w-12 h-1 py-[.18rem] mx-auto my-1 bg-secondary rounded-full"
              />
            </div>
          )}
          {hasNotch && (
            <div
              style={{
                width: 200,
                height: 35,
                borderRadius: "0 0 20px 20px",
              }}
              className="z-10 bg-black flex items-center justify-center"
            >
              <div
                style={{
                  boxShadow:
                    "0 1px 2px rgb(255 255 255 / 12%), inset 0 -0.5px 0 rgb(255 255 255 / 15%), inset 0 0 1px rgb(0 0 0), inset 0 2px 2px rgb(0 0 0 / 45%)",
                }}
                className="w-12 h-1 py-[.18rem] mx-auto my-1 bg-secondary rounded-full"
              />
            </div>
          )}
          <div className="gap-2 center">
            <WifiIcon className="w-4 h-4" />
            <RocketIcon className="w-4 h-4 p-[.08rem]" />
          </div>
        </div>
        {children}
      </div>
      {showBrowser ? (
        <div
          style={{ borderRadius: hasPill || hasNotch ? "0 0 50px 50px" : 50 }}
          className="absolute bottom-0 z-20 w-full p-2 bg-secondary"
        >
          <div className="flex justify-between px-3 py-2 text-foreground bg-background rounded-xl">
            <span>aA</span>
            <div className="gap-1 select-all center">
              <LockClosedIcon className="w-3 h-3 text-neutral-300" />
              <span>joinladder.com</span>
            </div>
            <span>
              <RefreshIcon className="w-5 h-5" />
            </span>
          </div>
          <div className="flex justify-between px-3 py-4 pb-4 text-foreground/60">
            <ChevronLeftIcon className="w-8 h-8" />
            <ChevronRightIcon className="w-8 h-8" />
            <Share2Icon className="w-7 h-7" />
            <BookmarkIcon className="w-7 h-7" />
            <CopyIcon className="w-7 h-7" />
          </div>
          <div className="w-36 h-1 py-[.18rem] mx-auto my-1 bg-white/90 rounded-full" />
        </div>
      ) : null}
    </div>
  );
};
