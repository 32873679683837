import { useCallback, useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { EachDaySectionDesktop } from "web/components/home/every-day-section/desktop";
import { EachDaySectionMobile } from "web/components/home/every-day-section/mobile";

export const EachDaySection = () => {
  const [isComplete, setIsComplete] = useState(false);
  const [hasCompletedExplosionComplete, setHasCompletedExplosionComplete] =
    useState(false);
  const isMobile = useIsMobile();

  const handleSetCompleted = useCallback(() => {
    setIsComplete(true);
    setHasCompletedExplosionComplete(true);
  }, []);

  return (
    <div>
      <div className={"lg:hidden pb-16"}>
        <EachDaySectionMobile
          {...{
            progress: 50,
            isComplete,
            setIsComplete,
          }}
        />
      </div>
      <div className="hidden lg:block">
        <Controller>
          <Scene
            triggerHook={isMobile ? 0.25 : 0}
            duration={300}
            enabled={!isMobile}
            pin={!isMobile}
          >
            {(progress) => {
              if (progress > 0.85 && !hasCompletedExplosionComplete) {
                handleSetCompleted();
              }
              return (
                <section>
                  <div
                    className={cn({
                      hidden: isMobile,
                    })}
                  >
                    <EachDaySectionDesktop
                      {...{
                        progress,
                        isComplete,
                        setIsComplete,
                      }}
                    />
                  </div>
                </section>
              );
            }}
          </Scene>
        </Controller>
      </div>
    </div>
  );
};
