import { motion } from "framer-motion";
import Image from "next/image";
import { useEffect } from "react";
import useInView from "react-cool-inview";
import { useVideo } from "react-use";
import { useWindowSize } from "ui/lib/use-breakpoint";

const MotionImage = motion(Image);

const textLine = {
  hidden: {
    opacity: 1,
  },
  visible: () => ({
    opacity: 1,
    transition: {
      staggerChildren: 0.04,
    },
  }),
};

const letter = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const SplitText = ({ text }) => {
  return text.split("").map((char, index) => {
    return (
      <motion.span key={char + "-" + index} variants={letter} className="">
        {char}
      </motion.span>
    );
  });
};

const Title = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: "all" }}
      className="mb-4 gap-y-1 lg:mb-16"
    >
      <motion.h3 className="text-lg text-black lg:text-4xl heading">
        EXPERIENCE AN app built for
      </motion.h3>
      <motion.h1
        custom={0}
        variants={textLine}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: "all" }}
        className="text-5xl text-black lg:text-6xl heading"
      >
        <SplitText text="strength training" />
      </motion.h1>
    </motion.div>
  );
};

export const InEarCoachingContainer = () => {
  const [video, videoState, videoControls] = useVideo(
    <motion.video
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.33 }}
      transition={{ delay: 0.25 }}
      className="w-full px-8 lg:px-auto lg:w-auto max-w-lg"
      autoPlay={false}
      controls={false}
      loop
      muted
      playsInline
      src="https://media.teams.joinladder.com/website/videos/app/homepage_wo_player_v0.mov"
    />
  );

  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    threshold: 0, // Default is 0
  });

  useEffect(() => {
    if (inView) {
      videoControls.play();
    } else {
      videoControls.pause();
    }
  }, [inView, videoControls]);

  const { width } = useWindowSize();

  return (
    <motion.div
      ref={observe}
      className="relative min-h-screen px-4 pb-36 lg:pb-56 center bg-basic"
    >
      {width < 1024 ? (
        <div className="flex-col max-w-full mx-auto text-center center">
          <div className="relative mb-4">
            <MotionImage
              width={200}
              height={200}
              src="https://ladderteams.imgix.net/website/airpods/left_0.png"
              alt="Airpods"
              className="absolute inset-y-0 left-0 w-32 my-auto ml-auto transform -translate-x-8 translate-y-16"
            />
            {video}
            <MotionImage
              width={200}
              height={200}
              src="https://ladderteams.imgix.net/website/airpods/right_0.png"
              alt="Airpods"
              className="absolute inset-y-0 right-0 w-32 my-auto ml-auto translate-x-8 -translate-y-8"
            />
          </div>
          <Title />
          <p className="text-lg" style={{ color: "#8B8B8B" }}>
            In-ear coaching, video demonstrations, and precise pacing built-in.
          </p>
        </div>
      ) : (
        <div className="relative w-full px-2 mx-auto max-w-7xl">
          <div className="text-center">
            <Title />
          </div>
          <div className="center">
            <div className="absolute left-0 z-30 w-5/6 lg:w-full lg:relative">
              <div className="w-full pl-2 space-y-24 transform -translate-y-24 lg:pl-6 lg:translate-x-16">
                <motion.h3
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 0.75, y: 0 }}
                  viewport={{ once: true, amount: "some" }}
                  transition={{ delay: 0.25 }}
                  className="ml-8 text-2xl text-black md:-translate-x-24 lg:text-4xl heading"
                >
                  Video <span className="block text-xl">Demonstrations</span>
                </motion.h3>
              </div>
              <div className="flex self-end transform ">
                <MotionImage
                  width={200}
                  height={200}
                  src="https://ladderteams.imgix.net/website/airpods/left_0.png"
                  className="relative w-32 mb-8 ml-auto lg:w-48 translate-x-24"
                />
              </div>
            </div>
            <div className="w-full transform -translate-y-8 center">
              {inView ? video : null}
            </div>
            <div className="absolute right-0 z-30 flex-col self-center w-2/6 translate-y-8 lg:w-full lg:relative">
              <div className="w-full mt-24 ml-8 space-y-16">
                <motion.h1
                  variants={textLine}
                  custom={10}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: "all" }}
                  className="text-xl text-black md:text-4xl xl:text-6xl heading"
                >
                  <SplitText text="In-ear Coaching" />
                </motion.h1>
                <div className="transform ">
                  <MotionImage
                    width={200}
                    height={200}
                    src="https://ladderteams.imgix.net/website/airpods/right_0.png"
                    className="relative w-32 transform -translate-x-28 lg:w-48"
                  />
                </div>
                <motion.h3
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 0.75, y: 0 }}
                  viewport={{ once: true, amount: "all" }}
                  className="text-2xl text-black lg:text-4xl heading"
                >
                  Precise <span className="block text-xl">Pacing built-in</span>
                </motion.h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};
