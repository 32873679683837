import Image from "next/image";
import { useRef } from "react";
import {
  SnapItem,
  SnapList,
  useScroll,
  useVisibleElements,
} from "react-snaplist-carousel";
import { ContentDots } from "ui/components/content-dots";
import { cn } from "ui/lib/utils";
import teams from "./teams.json";

const sortOrder = [
  "define", // MAIA
  "vitality", // BRIAN
  "transform", // SHELBY
  "forged", // COREY
  "movewell", // NICOLE
  "limitless", // KELLY
  "elevate", // ELISE
  "ascend", // ARIA
  "iconic", // SARAH
  "formation", // ROBIN
  "project alpha", // SAM T
  "body & bell", // LAUREN
  "align", // SASHA
  "evolve", // SAMANTHA
  "crews control", // ANDRE
  "maximus", // MAXIMUS (BOBBY & JOE)
  "resilient", // NEEN
];

function sortTeams(teams) {
  return teams.sort((a, b) => {
    const indexA = sortOrder.indexOf(a.name.toLowerCase());
    const indexB = sortOrder.indexOf(b.name.toLowerCase());

    if (indexA === -1 && indexB === -1) {
      // If neither team is in the sortOrder, sort alphabetically
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    if (indexA === -1) return 1; // a is not in sortOrder, so it goes to the end
    if (indexB === -1) return -1; // b is not in sortOrder, so it goes to the end

    return indexA - indexB;
  });
}

const TeamItem = ({ team, onClick }) => {
  return (
    <div key={team.id} className="relative group">
      <div
        className={cn(
          "group overflow-hidden bg-secondary relative rounded flex center group",
          "h-[50vh] max-h-[35rem] lg:h-[35rem]",
          "border transition-colors",
          "border-transparent lg:hover:border-neutral-500"
        )}
      >
        <Image
          // style={{
          //   backgroundImage: `url(${team.posterImgixURL + "?w=600"})`,
          // }}
          src={`${team.posterImgixURL + "?w=600"}`}
          width={650}
          height={850}
          alt={team.name}
          className={
            "absolute inset-0 w-full pointer-events-none bg-cover bg-top transition-all scale-100"
          }
        />
        <div className="absolute inset-0 w-full pointer-events-none transition-all duration-300 bg-gradient-to-t from-background/90 to-black/10 group-hover:from-background group-hover:to-transparent group-hover:bg-transparent mt-auto" />
        <div className="flex flex-col gap-4 relative justify-between p-2 mt-auto mb-8 text-center">
          <div className="text-foreground">
            <span className="">{team.coachNamesDesc}</span>
            <h3 className="text-3xl lg:text-4xl font-druk-wide text-foreground">
              {team.name}
            </h3>
            <p className="mt-1 px-10 text-accent-foreground opacity-60 group-hover:text-foreground">
              {team.tagline}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CoachContainer = () => {
  const sortedTeams = sortTeams(teams);
  const snapList = useRef(null);
  const goToSnapItem = useScroll({ ref: snapList });

  const visible = useVisibleElements(
    { debounce: 10, ref: snapList },
    (elements) => {
      switch (elements.length) {
        case 1:
          return elements[0];
        case 2:
        case 3:
          return elements[1];
        default:
          return elements[0];
      }
    }
  );

  return (
    <div className="relative z-10 min-h-screen pt-16 pb-32 lg:pb-56">
      <div
        style={{
          background: `radial-gradient(50% 50% at 50% 50%, #403C56 28.65%, rgba(64, 60, 86, 0) 100%)`,
        }}
        className="absolute inset-0 transform scale-110 -translate-y-24 rounded-full"
      />
      <div className="relative mb-12">
        <div className="max-w-5xl px-2 mx-auto space-y-6 text-center">
          <h2 className="hidden text-4xl text-center text-foreground lg:block lg:text-6xl heading">
            <span className="block mb-2 text-2xl lg:mb-4 lg:text-4xl">
              ONLY THE
            </span>{" "}
            BEST STRENGTH COACHES
          </h2>
          <h2 className="block text-3xl text-center text-foreground lg:hidden heading">
            ONLY THE BEST STRENGTH COACHES
          </h2>
        </div>
      </div>
      <div className="mb-[4rem] max-w-screen">
        <SnapList
          direction="horizontal"
          className="px-6 space-x-2 lg:space-x-4 overscroll-x-contain"
          ref={snapList}
        >
          {sortedTeams.map((team, index) => (
            <SnapItem
              snapAlign="center"
              className="flex flex-col w-11/12 md:w-[calc(100%/2-0.5rem)] lg:w-[calc(100%/3-1rem)]"
              key={index}
            >
              <TeamItem onClick={() => null} team={team} key={index} />
            </SnapItem>
          ))}
        </SnapList>
        <div className="my-4">
          <ContentDots
            length={teams.length}
            handleScrollTo={goToSnapItem}
            activeIndex={visible}
          />
        </div>
      </div>
    </div>
  );
};
