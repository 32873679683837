"use client";
import React, { useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { cn } from "ui/lib/utils";

const DURATION = 4000;

type ConfettiComponentProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConfettiComponent = React.memo<ConfettiComponentProps>(
  ({ show = false, setShow }) => {
    useEffect(() => {
      if (show) {
        setTimeout(() => setShow(false), DURATION - 500);
      }
    }, [show, setShow]);

    return (
      <div
        className={cn("absolute inset-0 z-0 center", {
          hidden: !show,
        })}
      >
        {show && (
          <ConfettiExplosion
            {...{
              colors: ["#fff", "#555555", "#636363"],
              force: 0.5,
              duration: DURATION,
              particleCount: 150,
              // floorHeight: 1200,
              // floorWidth: 1200,
            }}
          />
        )}
      </div>
    );
  }
);
