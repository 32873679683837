import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { captureEvent } from "ui/lib/captureEvent";
import getStripe from "ui/lib/get-stripe";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { useExperiment } from "web/state-containers/experiment";
import { useModals } from "./modals/container";
import { accessVariant } from "./text-node";

const MotionLink = motion(Link);

const PRICE_ID = "price_default_id";

const createCheckoutSessionID = async ({ priceID, promo }): Promise<string> => {
  const successUrl = new URL(
    `${process.env.NEXT_PUBLIC_DOMAIN}/stripe/success?session_id={CHECKOUT_SESSION_ID}&price_id=${priceID}`
  );

  const { sessionId } = await (
    await fetch(`/api/subscription/create-checkout-session`, {
      method: "POST",
      body: JSON.stringify({
        cancelUrl: process.env.NEXT_PUBLIC_DOMAIN + "/app/upgrade",
        priceId: priceID,
        promoCode: promo,
        successUrl,
      }),
    })
  ).json();

  return sessionId as string;
};

interface ActionProps
  extends Omit<React.ComponentPropsWithoutRef<typeof MotionLink>, "href"> {
  at: string;
  defaultClasses?: string;
  className?: string;
  event?: {
    category?: string;
    action?: string;
    label?: string;
    [key: string]: any;
  };
}

export const BlackFridayAction = React.memo<ActionProps>(
  ({
    at,
    defaultClasses = "text-lg rounded-full cta",
    className,
    event,
    ...props
  }) => {
    const { query } = useRouter();
    const isMobile = useIsMobile();
    const { variant } = useExperiment();
    const { toggleShowQRModal } = useModals();

    const accessedVariant = accessVariant(at, variant as string);

    const handleCreateCheckout = async () => {
      captureEvent({
        action: "Stripe_Claim_Offer_Clicked",
        label: "stripe claim offer clicked",
        category: "stripe_subscribe",
        value: "cta clicked",
        promo_code: query.promoCode ?? "none",
        path: window.location.pathname + window.location.search,
      });

      const sessionId = await createCheckoutSessionID({
        priceID: "price_1OCUfjGYIHpZzlGcvhs7Evqy",
        promo: "promo_1QKoOjGYIHpZzlGcUmza5stQ",
      });

      const stripe = await getStripe();
      await stripe.redirectToCheckout({
        sessionId,
      });
    };

    const handleClick = async (
      e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
    ) => {
      e.preventDefault();
      await handleCreateCheckout();
    };

    if (!isMobile) {
      return (
        <button
          onClick={handleClick}
          className={cn(
            "relative inline-block cursor-pointer",
            defaultClasses,
            className
          )}
        >
          {accessedVariant.label}
        </button>
      );
    }

    return (
      <MotionLink
        {...props}
        href="#"
        onClick={handleClick}
        aria-label={accessedVariant.label}
        className={cn(
          "relative inline-block cursor-pointer",
          defaultClasses,
          className
        )}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.975 }}
      >
        {accessedVariant.label}
      </MotionLink>
    );
  }
);

BlackFridayAction.displayName = "BlackFridayAction";
