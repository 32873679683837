import { motion } from "framer-motion";
import Image from "next/image";
import { useIsMobile } from "ui/lib/use-breakpoint";
import data from "web/data.json";
import { Action } from "../action";
import { AppReviewsContainer } from "./app-reviews-container";
import { CoachContainer } from "./coach-container";
import { EachDaySection } from "./every-day-section";
import { FaqContainer } from "./faq-container";
import { Featured } from "./featured";
import { Hero } from "./hero";
import { InEarCoachingContainer } from "./in-ear-coaching-container";
import { PaymentTeaser } from "./payment-teaser";
import { SaveTimeContainer } from "./save-time";
import { TrainingContainer } from "./training-container";
import { VideoTestimonialsContainer } from "./video-testimonials-container";

const MotionImage = motion(Image);

export const CommunityContainer = () => {
  const isMobile = useIsMobile();

  return (
    <div className="bg-basic">
      <div className="container flex flex-col px-4 mx-auto lg:flex-row">
        <div className="flex flex-col justify-center w-full px-4 mb-4 text-center xl:w-5/12 lg:w-1/2 lg:mb-16 lg:text-left">
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 1 }}
            className="text-lg text-black lg:text-4xl heading"
          >
            Join a community
          </motion.h3>
          <motion.h1
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 1 }}
            className="mt-1 text-4xl text-black lg:text-6xl heading"
          >
            THAT SHARES YOUR PASSION
          </motion.h1>
          <p className="mt-8 text-black lg:text-lg opacity-60">
            Start your free 7 day trial now.
          </p>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 1 }}
            className="relative center lg:justify-start"
          >
            <Action
              at="home.hero.cta"
              event={{ action: "find_my_plan_cta_clicked" }}
              className="px-10 py-4 mt-8 text-foreground bg-background rounded-full cta"
            />
          </motion.div>
        </div>
        <MotionImage
          width={1000}
          height={1000}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, amount: 0.5 }}
          className="w-full h-full px-2 mt-8 transform bg-center bg-no-repeat bg-cover lg:translate-x-16 xl:w-7/12 lg:w-1/2 scale-118"
          alt="community"
          src={`https://ladderteams.imgix.net/website/community/main.png`}
        />
      </div>
    </div>
  );
};

export const FindATeam = () => {
  const isMobile = useIsMobile();
  return (
    <div className="container relative px-4 pb-32 mx-auto lg:pb-56">
      <div className="flex flex-col items-center lg:flex-row">
        <div className="relative z-10 flex flex-col justify-center w-full max-w-xl mb-8 lg:mb-0 lg:w-5/12">
          <div className="max-w-5xl mx-auto space-y-8 text-center lg:px-2 lg:text-left">
            <h2 className="text-4xl text-foreground lg:text-6xl heading">
              <span className="block mb-1 text-lg lg:text-4xl">
                find the perfect plan
              </span>{" "}
              for you
            </h2>
            <p className="text-foreground lg:pr-12 lg:text-lg opacity-80">
              Receive a personalized recommendation based on your training goals
              and preferred training styles.
            </p>
            <Action
              at="home.hero.cta"
              event={{ action: "find_my_plan_cta_clicked" }}
              className="px-10 py-4 text-black rounded-full cta bg-primary"
            />
          </div>
        </div>
        <div className="relative w-full transform translate-x-4 lg:w-7/12 lg:translate-x-24 xl:translate-x-40 2xl:translate-x-48">
          <Image
            width={600}
            height={900}
            alt="ladder coaches"
            className="relative z-10 w-full"
            src={`https://ladderteams.imgix.net/website/coaches/pyramid-v1.png`}
          />
          <div
            style={{
              background: `radial-gradient(50% 50% at 50% 50%, #403C56 28.65%, rgba(64, 60, 86, 0) 100%)`,
            }}
            className="absolute inset-0 transform scale-110 -translate-y-24 rounded-full"
          />
        </div>
      </div>
    </div>
  );
};

export const ForBusyPeople = () => {
  return (
    <div className="container relative z-40 flex-col max-w-screen-xl px-4 pt-[150px] lg:pt-[300px] pb-24 mx-auto bg-background text-foreground lg:px-8 center">
      <h2 className="mb-12 text-2xl text-center lg:text-5xl heading">
        For busy people who prioritize fitness
      </h2>
      <div className="grid grid-cols-1 gap-6 lg:gap-8 md:grid-cols-2 lg:grid-cols-4">
        {data.home.beni.map(({ title, details, icon }, i) => (
          <div className="flex-col px-16 text-center lg:px-0 center" key={i}>
            <img
              alt={icon}
              className="w-12 h-12 mb-4 md:w-16 md:h-16"
              src={`https://ladderteams.imgix.net/website/icons/${icon}.svg`}
            />
            <h3 className="mb-1 text-lg text-foreground capitalize md:mb-2 heading">
              {title}
            </h3>
            <p className="text-foreground text-opacity-75">{details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const HomeComponent = () => {
  return (
    <>
      <Hero />
      <Featured />
      <ForBusyPeople />
      <EachDaySection />
      <SaveTimeContainer />
      <InEarCoachingContainer />
      <TrainingContainer />
      <CoachContainer />
      <FindATeam />
      <VideoTestimonialsContainer />
      <AppReviewsContainer />
      <PaymentTeaser />
      <FaqContainer />
      <CommunityContainer />
    </>
  );
};
